import { h, createRef, Component } from "preact";
import style from "./style.less";

const getTeamEncryptedId = async (widgetId) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/team_info?widget_id=${widgetId}&limit=3`
    );
    const data = await response.json();

    return data.team.encrypted_id;
  } catch (error) {
    console.log(error);
  }
};

export default class IFrameApp extends Component {
  ref = createRef();

  componentDidMount() {
    const { widgetId } = this.props;

    const removeAntiClick = () =>
      getTeamEncryptedId(widgetId)
        .then((encryptedId) => {
          this.ref.current.contentWindow.postMessage(encryptedId, "*");
        })
        .catch(console.error);

    window.addEventListener("message", (event) => {
      if (event.data === "sendTeamId") {
        removeAntiClick();
      }
    });
  }

  render() {
    const { widgetId } = this.props;
    return (
      <iframe
        ref={this.ref}
        title="Message Us"
        loading="eager"
        src={`${process.env.PATIENT_CLIENT_URL}#/widget/${widgetId}`}
        className={style.iframe}
        tabIndex="0"
      ></iframe>
    );
  }
}
