import { h, Component } from "preact";
import classNames from "classnames";
import isMobile from "helpers/isMobile";
/** @jsx h */

import IFrameApp from "./IFrameApp";
import KlaraButton from "../components/KlaraButton";
import CloseButton from "../components/CloseButton";
import { getTeamInfo } from "../helpers/api";
import initPublicInterface from "../helpers/publicInterface";
import { setIFrameDimensions } from "../helpers/iFrame";
import crossWindowApiListener from "../helpers/crossWindowApiListener";
import style from "../style";

const saveWidgetState = (state) => {
  window.localStorage.setItem("widget-state", (state && "open") || "closed");
};

const getWidgetState = () => window.localStorage.getItem("widget-state");

const TOGGLE_WIDGET = ({ widgetOpen, ...state }) => {
  saveWidgetState(!widgetOpen);
  return {
    ...state,
    widgetOpen: !widgetOpen,
  };
};

const SET_WIDGET_OPEN_STATE = ({ ...state }, isOpen) => {
  saveWidgetState(isOpen);
  return {
    ...state,
    widgetOpen: isOpen,
  };
};

const SET_WIDGET_FULL_WINDOW = ({ ...state }, fullWindow) => ({
  ...state,
  widgetFullWindow: fullWindow,
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wasAlreadyOpened: false,
    };
  }

  componentDidMount() {
    const { mutate, widgetId } = this.props;

    initPublicInterface(mutate);

    if (widgetId) {
      this.fetchTeamInfo(widgetId);
    }

    crossWindowApiListener.startListening({
      setWidgetFullWindow: (fullWindow) =>
        mutate(SET_WIDGET_FULL_WINDOW, fullWindow),
    });
  }

  componentDidUpdate(previousProps) {
    // For dev logs not included in production build
    if (process.env.NODE_ENV === "development") {
      require("../helpers/devStats").default(this.props, arguments[0]);
    }

    if (!this.props.widgetId) return;
    const {
      iFrameContainer,
      widgetId,
      widgetOpen,
      embedded,
      widgetFullWindow,
    } = this.props;

    if (!embedded) {
      const parentBodyClassList = parent.document.body.classList;

      if (isMobile() && widgetOpen) {
        parentBodyClassList.add(style.klaraWidgetActiveMobile);
      } else {
        parentBodyClassList.remove(style.klaraWidgetActiveMobile);
      }
    }

    const widgetIdExistsAndIsDifferentFromLastRender =
      !!widgetId && previousProps.widgetId !== widgetId;

    if (widgetIdExistsAndIsDifferentFromLastRender) {
      this.fetchTeamInfo(widgetId);
    }

    setIFrameDimensions(iFrameContainer, {
      widgetOpen,
      embedded,
      widgetFullWindow,
    });

    // The following code is to make sure we only render the widget content after
    // user actively opened it by clicking on the widget button
    const widgetWasJustOpened =
      previousProps.widgetOpen === false && this.props.widgetOpen === true;
    const widgetWasNotOpenedYet = this.state.wasAlreadyOpened === false;

    if (widgetWasJustOpened && widgetWasNotOpenedYet) {
      this.setState({
        wasAlreadyOpened: true,
      });
    }
  }

  fetchTeamInfo(widgetId) {
    const { mutate, embedded } = this.props;
    const localState = getWidgetState();
    const mobile = isMobile();
    if (localState !== null) {
      mutate(SET_WIDGET_OPEN_STATE, localState !== "closed" && !mobile);
      return;
    }

    getTeamInfo(widgetId).then((teamData) => {
      mutate(
        SET_WIDGET_OPEN_STATE,
        embedded || (!mobile && !teamData.widget.closed_by_default)
      );
    });
  }

  render() {
    const { wasAlreadyOpened } = this.state;
    const { widgetOpen, mutation, embedded, widgetId, widgetFullWindow } =
      this.props;

    const useFullScreenStyle = embedded || isMobile() || widgetFullWindow;
    const appStyle = useFullScreenStyle ? style.appFullScreen : style.app;
    const klaraButtonId = widgetOpen
      ? "widget-close-toggle"
      : "widget-open-toggle";

    const widgetBodyClasses = classNames(
      style.widgetBody,
      { [style.hide]: !widgetOpen },
      { [style.widgetBodyFullScreen]: useFullScreenStyle },
      { [style.widgetBodySize]: !useFullScreenStyle },
      { [style.widgetMobileScrolling]: isMobile() }
    );

    return (
      <div id="klaraWidgetApp" className={appStyle}>
        {!embedded && (!isMobile() || !widgetOpen) && (
          <KlaraButton
            onClick={mutation(TOGGLE_WIDGET)}
            open={widgetOpen}
            isMobile={isMobile()}
            dataTestId={klaraButtonId}
          />
        )}
        {wasAlreadyOpened ? (
          <div className={widgetBodyClasses} id="klaraWidgetContent">
            {isMobile() && !embedded && (
              <CloseButton
                onClick={mutation(TOGGLE_WIDGET)}
                className={style.floatingCloseButton}
                dataTestId="widget-close-toggle"
              />
            )}
            <IFrameApp widgetId={widgetId} />
          </div>
        ) : null}
      </div>
    );
  }
}

export { default as initSate } from "./state";
export default App;
