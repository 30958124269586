import { render, h } from 'preact-cycle';
import './style';
import { IEVersion } from './helpers/IEVersion';
import isMobile from './helpers/isMobile';

function init(iFrame) {
    const App = require('./containers/app').default;
    const appInitState = require('./containers/app').initSate(iFrame);

    if (isMobile()) {
      iFrame.contentWindow.document.body.style.margin = 0;
      iFrame.contentWindow.document.body.style.padding = 0;
    }

    render(App, appInitState, iFrame.contentWindow.document.body);
}

function canShowWidget() {
  return (process.env.MAINTENANCE_MODE !== 'ON') && (
    process.env.NODE_ENV === 'development' || !IEVersion() || IEVersion() > 10
  );
}

if (module.hot) {
	// require('preact/devtools');   // turn this on if you want to enable React DevTools!
	module.hot.accept('./containers/app', () => requestAnimationFrame(init) );
}

if (canShowWidget()) {
  const iFrame = window.document.createElement('iframe');
  iFrame.setAttribute('allowfullscreen', '');
  iFrame.setAttribute('title', 'Message Us');
  iFrame.setAttribute('loading', 'eager');

  iFrame.style.bottom = '0';
  iFrame.style.right = '0';
  iFrame.style.border = 'none';
  iFrame.style.position = 'fixed';
  iFrame.style.zIndex = '2147483647';
  iFrame.style.maxHeight = '100vh';
  window.document.body.appendChild(iFrame);

  setTimeout(() => {
    iFrame.contentWindow.document.head.innerHTML = `<link rel="stylesheet" type="text/css" href="${process.env.BASE_URL}/style.css"/>`;
    init(iFrame);
  }, 300);

}
